<template>
  <div class="infomation-detail">
    <div class="g-section">
      <div class="g-wrap relevantInfo-flex">
        <div class="g-content" ref="mainContainer" v-loading="loading">
          <div v-if="loaded">
            <h3 class="title">{{ model.Name }}</h3>
            <div class="attrs">
              <span class="time">发表时间：{{ time }}</span>
              <span class="source"
                >来源：{{ model.Source || "广东轻工职业技术学院" }}</span
              >
            </div>
            <div class="content" v-html="model.Description"></div>
            <div class="relative">
              <div class="prev">
                上一篇：
                <span
                  v-if="list[nowIndex - 1] && list[nowIndex - 1].Name"
                  @click="
                    handleSwitch(list[nowIndex - 1] && list[nowIndex - 1].Id)
                  "
                  class="link-text text-over"
                >
                  {{ list[nowIndex - 1] && list[nowIndex - 1].Name }}
                </span>
                <span v-else>没有了</span>
              </div>
              <div class="next">
                下一篇：
                <span
                  v-if="list[nowIndex + 1] && list[nowIndex + 1].Name"
                  @click="
                    handleSwitch(list[nowIndex + 1] && list[nowIndex + 1].Id)
                  "
                  class="link-text text-over"
                >
                  {{ list[nowIndex + 1] && list[nowIndex + 1].Name }}
                </span>
                <span v-else>没有了</span>
              </div>
            </div>
          </div>
          <div class="error-data" v-else>暂无信息</div>
        </div>
        <!-- <div class="relevantInfo-box">
          <div class="relevantInfo-title">
            <span class="tltle-icon mr-8"></span><span>相关资讯</span>
          </div>
          <div
            class="relevantInfo-content"
            v-for="(item, index) in 4"
            :key="index"
          >
            <div class="content-imgBox">
              <img src="#" class="content-img" alt="" />
            </div>
            <div class="content-text">
              <span class="content-infoName">
                郑州铁路职业技术学院与新乡市签约合作
              </span>
              <div class="content-infoTime">2020-08-05</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { Loading } from "element-ui";
import { getInfo, getPageList } from "@/api/achievement";
import contentTools from "@/mixins/content-tools";
export default {
  name: "AchievementDetail",
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      model: {},
      time: "",
      id: null,
      nowIndex: null,
      list: [],
      loaded: true
    };
  },
  created() {
    this.init();
    this.getPageList();
  },
  mounted() {
    // this.getNewsList();
  },
  watch: {
    $route() {
      // this.init();
    }
  },

  methods: {
    async getPageList() {
      this.loading = true;
      const { Result } = await getPageList();
      this.list = Result.Data;
      Result.Data.forEach((el, i) => {
        if (el.Id == this.model.Id) {
          this.nowIndex = i;
        } else {
          return false;
        }
      });
      this.loading = false;
    },
    async loadData() {
      this.loading = true;

      const res = await getInfo({ id: this.id });
      console.log(res);
      this.model = res.Result;
      this.time = this.formartDate(res.Timestamp, "minutes");
      this.loading = false;
    },
    init() {
      this.id = this.$route.query.id;
      this.loadData();
    },
    handleSwitch(id) {
      this.id = id;
      this.loadData();
      this.getPageList();
    }
  }
};
</script>

<style lang="scss" scoped>
.infomation-detail {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  flex: 1;
  .relevantInfo-flex {
    display: flex;
    .relevantInfo-box {
      cursor: pointer;
      width: 34%;
      box-sizing: border-box;
      padding-left: 32px;
      margin-top: 40px;
      .relevantInfo-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        .tltle-icon {
          display: inline-block;
          width: 4px;
          height: 20px;
          background: #3d5afe;
          border-radius: 2px;
        }
      }
      .relevantInfo-content {
        display: flex;
        margin-bottom: 16px;
        .content-imgBox {
          width: 110px;
          height: 73px;
          .content-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .content-text {
          width: 214px;
          height: 73px;
          margin-left: 16px;
          .content-infoName {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
          }
          .content-infoName:hover {
            color: #3d5afe;
          }
          .content-infoTime {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
          }
        }
      }
    }
  }
  .g-section {
    background: #ffffff;
    .g-content {
      width: 100%;
    }

    .title {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      margin: 40px 0 16px 0;
    }
    .attrs {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      padding-bottom: 24px;
      border-bottom: 1px solid #eeeeee;
      .time {
        padding-right: 64px;
      }
    }
    .content {
      padding: 40px 0;
      font-size: 18px;
      color: #666666;
      line-height: 1.8em;
      text-indent: 2em;
      /deep/ img {
        display: block;
      }
      min-height: 30vh;
    }
    .relative {
      display: flex;
      justify-content: space-between;
      padding: 16px 0 40px 0;
      border-top: 1px solid #eee;
      font-size: 16px;
      color: #999999;
      .link-text {
        max-width: 500px;
        cursor: pointer;
        &:hover {
          color: #598df3;
        }
      }
      .prev {
        display: flex;
        flex: 1;
      }
      .next {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }
}
</style>
